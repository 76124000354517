const theme = {
  colors: {
    primary: '#4F2AAB',
    secondary: '#993aa1',
    textBlack: 'rgba(0,0,0,0.95)',
    textGrey: 'rgba(0,0,0,0.65)',
    textPrimary: 'rgba(0,0,0,0.75)',
  },
  fontSizes: [12, 14, 17, 20, 24, 32],
  width: 1060,
  media: {
    mobile: 576,
    desktop: 992,
  },
}
// Orbit
// widthBreakpointMediumMobile: 414
// widthBreakpointLargeMobile: 576
// widthBreakpointTablet: 768
// widthBreakpointDesktop: 992
// widthBreakpointLargeDesktop: 1200

export default theme
